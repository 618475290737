import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

import img7 from "./../images/aboutus/office.png";
import img8 from "./../images/aboutus/img8.webp";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Our History"
        description="With 24 years of trading experience, Legacy Lifeline has established itself as a leading player in the global textiles industry. Our expertise spans across textiles, home textiles, polymers, and raw materials, making us a one-stop-shop for all your textile needs. Founded in 2000, our company has grown from a small trading firm to a renowned name in the industry. Our journey has been marked by a commitment to quality, innovation, and customer satisfaction. Over the years, we have built strong relationships with suppliers and customers worldwide, enabling us to offer a diverse range of products at competitive prices."
         buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc={img7}
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />

      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Our Factory"
        description="Legacy Lifeline has warehouses in many cities in China, with large inventory and rich types of products, which can be delivered to the nearest port according to customers' needs. Our company is a senior partner of Chinese chemical manufacturers such as Sinopec, PetroChina, Yanchang Petroleum, China Shenhua (Coal Chemical), Lanzhou Petrochemical, and Yangzi BASF. And with Saudi Arabia, SABIC, South Korea LG Chemical, LG Engineering Plastics (Tianjin), South Korea Kumho, South Korea Hanwha, Thailand SCG, the United States Dow Chemical, Lotte New Materials, Lotte Petrochemical, Japan's Sumitomo, Mitsubishi Electric, and the United States Exxon Mobil, Russia’s sibur and other petrochemical plants have established in-depth cooperative relations."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        textOnLeft={false}
        imageSrc={img8}
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />

      {/* <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      /> */}

      <TeamCardGrid subheading={<Subheading></Subheading>} />
      <Features
        subheading={<Subheading></Subheading>}
        heading="Our Values"
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Our 24/7 support ensures you get help anytime, day or night, for uninterrupted service.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "Strong teams collaborate effectively, drive innovation, and achieve success through trust, communication, and shared goals.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Customer satisfaction is key for us to success, fostering loyalty, trust, and long-term relationships with businesses.",
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
