import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "India",
            description: (
              <>
                <Address>
                  <AddressLine>Office No. - 411</AddressLine>
                  <AddressLine>Shilp One, Khyati Circle, Thaltej</AddressLine>
                  <AddressLine>Shilaj Road, Ahmedabad - 380059</AddressLine>
                </Address>
                <Email>legacylifelineglobal@gmail.com</Email>
                <Phone>+91 9115113111</Phone>
              </>
            )
          },
          {
            title: "China",
            description: (
              <>
                <Address>
                  <AddressLine>Roome No. 1712-2, Qinye Plaza</AddressLine>
                  <AddressLine>Keqiao, Shaoxing, Zhejiang</AddressLine>
                  <AddressLine>China, PC-312030</AddressLine>
                </Address>
                <Email>kammaljumanji@madoctextile.com</Email>
                <Phone>+85 575-81112129</Phone>
              </>
            )
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
