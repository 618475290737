import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

// Styled components
const CarouselContainer = tw.div`relative w-full overflow-hidden`;
const SlideContainer = tw.div`flex transition-transform duration-700 ease-linear`;
const Slide = styled.div`
  ${tw`flex items-center justify-center text-white`}
  background-image: url('${props => props.bgImage}');
  background-size: cover;
  background-position: center;
  min-width: 100%;
  height: ${(props) => props.height || '100vh'};  // Dynamic height
`;
const SlideContent = tw.div`p-20`;
const Description = tw.p`font-bold mb-4`;
const Title = tw.h1`text-2xl font-bold text-[#243e63]`;
const Button = tw.a`bg-primary-500 border-0 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded`;

// Arrow styles
const ArrowContainer = tw.div`absolute top-1/2 transform -translate-y-1/2 flex items-center justify-center w-12 h-12 bg-gray-800 bg-opacity-50 text-white rounded-full cursor-pointer z-10`;
const PrevArrow = styled(ArrowContainer)`
  left: 2%;  // Position the left arrow
`;
const NextArrow = styled(ArrowContainer)`
  right: 2%;  // Position the right arrow
`;

// Carousel Component
const Carousel = ({ slides, autoSlide = true, interval = 3000, imgHeight }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const newIndex = (currentIndex === 0) ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const newIndex = (currentIndex === slides.length - 1) ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    if (autoSlide) {
      const timer = setInterval(() => {
        goToNext();
      }, interval);
      return () => clearInterval(timer);
    }
  }, [currentIndex, slides.length, autoSlide, interval]);

  return (
    <CarouselContainer>
      <SlideContainer style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          <Slide key={index} bgImage={slide.image} height={imgHeight}>
            <SlideContent>
              <Title>{slide.title}</Title>
              <Description>{slide.description}</Description>
              <Button href="./contact-us">{slide.buttonText}</Button>
            </SlideContent>
          </Slide>
        ))}
      </SlideContainer>

      {/* Arrows */}
      <PrevArrow onClick={goToPrevious}>❮</PrevArrow>
      <NextArrow onClick={goToNext}>❯</NextArrow>
    </CarouselContainer>
  );
};

export default Carousel;
